import React, { FunctionComponent } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Tag } from "../utils/models";
import { Card } from "../components/card";
import slugify from "slugify";
import { Container, Grid } from "../components/common";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import SEO from "../components/seo";
import Theme from "../styles/theme";

interface TagsPageProps {
  data: {
    allTags: {
      edges: Array<{ node: Tag }>;
    };
  };
  location: Location;
}

const TagSvgIcon = styled.img`
  max-height: 55px;
`;

const TagName = styled.p`
  margin: 0 !important;
`;

const TagsContainer = styled.div`
  padding: 0 30px;
`;

export const TagGrid = styled(Container)<{ columns?: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns ? props.columns : 3}, 1fr);
  grid-gap: 30px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    grid-template-columns: repeat(2, 2fr);
    padding: 0 20px;
  }
`;

const TagsPage: FunctionComponent<TagsPageProps> = ({ data, location }) => {
  const tags = data.allTags.edges.map(node => node.node);

  return (
    <Layout bigHeader={false} fixedLogo={true} dark={true}>
      <SEO
        location={location}
        title={`Tags`}
        type={`Series`}
      />
      <TagsContainer>
        <TagGrid columns={6}>
          {tags.map((tag, index) => (
            <Card
              key={index}
              path={`/tag/${slugify(tag.name, { lower: true })}`}
              compact={true}
              style={{ textAlign: 'center' }}
            >
              {tag.icon.extension !== 'svg'
                ? <GatsbyImage image={tag.icon.childImageSharp.gatsbyImageData} alt={tag.name} />
                : <TagSvgIcon src={tag.icon.publicURL} alt={tag.name} />
              }
              <TagName>
                {tag.name}
              </TagName>
            </Card>
          ))}
        </TagGrid>
      </TagsContainer>
    </Layout>
  );
};

export default TagsPage;

export const query = graphql`{
  allTags {
    edges {
      node {
        name
        icon {
          childImageSharp {
            gatsbyImageData(height: 55)
          }
          extension
          publicURL
        }
      }
    }
  }
}
`;
